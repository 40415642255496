function assignDefaultPermissions(permissions) {

    if (typeof permissions.cms === "undefined") {
        permissions.cms = false;
    }

    if (typeof permissions.inventory === "undefined") {
        permissions.inventory = false;
    }

    if (typeof permissions.dispatch === "undefined") {
        permissions.dispatch = false;
    }

    if (typeof permissions.sticker === "undefined") {
        permissions.sticker = false;
    }

    return permissions;

}


export const CompanyUtil = {
    assignDefaultPermissions
}

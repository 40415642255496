<template>
	<b-modal
		id="send-email-notification"
		title="Email Notification"
		ok-title="Send"
		ref="modal"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div>
			Are you sure want to send <br />
			a welcome email to
			<strong>{{ item.companyDetails ? item.companyDetails.name : '' }}</strong>
			company?
		</div>

		<table class="table mt-4">
			<thead>
				<tr>
					<th>&nbsp;</th>
					<th>Count</th>
					<th>Details</th>
				</tr>
			</thead>
			<tbody>
				<tr scope="row">
					<th>STORAGE LOCATIONS</th>
					<td>
						{{ item.storageLocations ? item.storageLocations.length : 0 }}
					</td>
					<td>
						<ul class="pl-0">
							<li v-for="(item, index) in item.storageLocations" :key="index">
								{{ item.address }}
							</li>
						</ul>
					</td>
				</tr>
				<tr scope="row">
					<th>ACTIVE USERS</th>
					<td>{{ item.users ? item.users.length : 0 }}</td>
					<td>
						<ul class="pl-0">
							<li v-for="(item, index) in item.users" :key="index">
								{{ item.firstName }} {{ item.lastName }}
							</li>
						</ul>
					</td>
				</tr>
				<tr scope="row">
					<th>CONNECTIONS</th>
					<td>{{ item.connections ? item.connections.length : 0 }}</td>
					<td>
						<ul class="pl-0">
							<li v-for="(item, index) in item.connections" :key="index">
								{{ item.connectedCompany.address }}
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
	</b-modal>
</template>

<script>
// API
import api from '@/api/notificationsApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';

export default {
	name: 'SendEmailNotification',
	components: {
		Loading,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	methods: {
		async handleOk(evt) {
			evt.preventDefault();
			this.isLoading = true;

			try {
				await api.newCompany(this.item.companyDetails.id, this.loggedUser.id);

				const companySetupParam = {
					companyId: this.item.companyDetails.id,
					data: { emailSent: true },
				};
				await this.$store.dispatch(
					'updateCompanySetupStatus',
					companySetupParam
				);

				this.$toaster.success(
					`An email to ${this.item.companyDetails.name} was sent successfully.`
				);
				this.$refs.modal.hide();
				EventBus.$emit('onEmailSent');
			} catch (error) {
				this.$toaster.error('Oops! Something went wrong.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>
<template>
	<div class="animated fadeIn">
		<b-card title="Company" sub-title="Manages the company registration">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toogle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="parentCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.parentCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>

									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Industry">
											<v-select class="style-chooser" label="text" :options="industryOptions"
												:reduce="(industry) => industry" v-model="filterBy.industry">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>


								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-company v-show="!isViewer">
								Add Company
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Companies in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Companies to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(parentCompany)="row">
						{{ row.item.parentCompany ? row.item.parentCompany : 'N/A' }}
					</template>
					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-b-modal.edit-company v-b-tooltip.hover.top="'Edit Details'" variant="dark"
								@click.stop="
									updateSelCompany(row.item, row.index, $event.target)
								" class="mr-1" v-show="!isViewer && !isParentCompany(row.item)">
								<i class="fa fa-pencil"></i>
							</b-button>

							<b-button v-if="
								row.item.companySetupStatus &&
								!row.item.companySetupStatus.emailSent &&
								row.item.companySetupStatus.hasConnection &&
								row.item.companySetupStatus.hasStorageLocation &&
								row.item.companySetupStatus.hasActiveUser
							" size="sm" v-b-tooltip.hover.top="'Send Welcome Notification'" variant="primary" class="mr-1"
								@click="openEmailConfirmation(row.item)" v-show="!isViewer && !isParentCompany(row.item)">
								<i class="fa fa-send"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelCompany(row.item)" v-if="
							!isViewer &&
							!isParentCompany(row.item) &&
							!isAdminCompany(row.item)
						">
							<b-form-checkbox name="status" v-b-modal.activate-company v-model="row.item.isActive" switch>
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>{{
							row.item.isActive === 'true' ? 'Active' : 'Inactive'
						}}</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<CompanyDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<SendEmailNotification :item="companyDetails" />
		<AddCompany :allIndustriesObj="allIndustriesObj" />
		<EditCompany :allIndustriesObj="allIndustriesObj" />
		<ActivateCompany />
		<CompanyLogoImageView :imageDetails="imageDetails" />
	</div>
</template>

<script>
// Component
import AddCompany from './company/AddCompany';
import EditCompany from './company/EditCompany';
import SendEmailNotification from './company/SendEmailNotification';
import ActivateCompany from './company/ActivateCompany';
import CompanyDetailsView from './company/CompanyDetailsView';
import CompanyLogoImageView from './company/CompanyLogoImageView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// DAO & API
import industryDAO from '@/database/industries';
import companyApi from '@/api/companyApi';
import storageLocationApi from '@/api/storageLocationApi';
import connectionApi from '@/api/connectionApi';
import userApi from '@/api/userApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'company',
	components: {
		AddCompany,
		EditCompany,
		SendEmailNotification,
		ActivateCompany,
		CompanyDetailsView,
		CompanyLogoImageView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'type',
					sortable: true,
				},
				{
					key: 'industry',
					sortable: false,
				},
				{
					key: 'parentCompany',
					sortable: false,
				},
				'actions',
				'status',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			allIndustriesObj: {},
			allCompaniesObj: {},
			selCompany: {},
			companyDetails: {},

			parentCompanyOptions: [],
			industryOptions: [],
			statusOptions: config.statusOptionsWithDefault,

			defaultFilterBy: {
				parentCompany: Object.assign({}, config.companyDefaultValue),
				industry: Object.assign({}, config.industryDefaultValue),
				isActive: null,
			},
			filterBy: {
				parentCompany: Object.assign({}, config.companyDefaultValue),
				industry: Object.assign({}, config.industryDefaultValue),
				isActive: null,
			},
			prevFilter: {},
			imageDetails: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},

	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Name: 'name',
				Description: 'description',
				Type: 'type',
				Industry: 'industry',
				Address: 'address',
				'Contact Person': 'contactPerson',
				'Contact Number': 'contactNo',
				Email: 'emailAddress',
				Brand: 'brand',
				'Branch Code': 'branchCode',
				'Parent Company': 'parentCompany',
				'Number of Users': 'noOfUsers',
				'Number of Storage Locations': 'noOfStorageLocations',
				'Number of Inactive Nodes': 'noOfInactiveNodes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Company-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.allIndustriesObj = await industryDAO.getAllIndustries();
				this.industryOptions = DropDownItemsUtil.retrieveIndustryItems(
					this.allIndustriesObj,
					false
				);

				this.allCompaniesObj = this.$store.getters.companies;
				this.parentCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj,
					this.loggedUserCompany
				);

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddCompany', (data) => {
			// update cache for dependent data updates
			this.$store.dispatch('updateAllConnections', data.connections);

			// proceed updating the table
			this.updateTable(data.company);
		});
		EventBus.$on('onCloseEditCompany', (data) => {
			// update cache for dependent data updates
			_.forEach(data.subCompanies, (subCompany) => {
				this.allCompaniesObj[subCompany.id] = subCompany;
			});
			this.$store.dispatch('updateAllStorageLocations', data.storageLocations);
			this.$store.dispatch('updateAllUsers', data.users);
			this.$store.dispatch('updateAllConnections', data.connections);

			// proceed updating the table
			this.updateTable(data.company);
		});
		EventBus.$on('onEmailSent', (companyObj) => {
			this.updateTable(companyObj);

			// reset company details for notification
			this.companyDetails = {};
		});
	},
	methods: {
		updateTable(companyObj) {
			if (!_.isEmpty(companyObj)) {
				this.allCompaniesObj[companyObj.id] = companyObj;
				this.$store.dispatch('updateAllCompanies', this.allCompaniesObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = Object.assign({}, this.allCompaniesObj);

			_.forEach(this.allCompaniesObj, (company, companyId) => {

				if (
					this.filterBy.parentCompany.id !== null &&
					this.filterBy.parentCompany.id !== company.parentCompanyId &&
					this.filterBy.parentCompany.id !== company.id
				) {
					delete filteredObj[companyId];
				}

				if (this.filterBy.isActive !== null &&
					this.filterBy.isActive !== company.isActive) {
					delete filteredObj[companyId];
				}

				if (
					this.filterBy.industry.value !== null &&
					this.filterBy.industry.value !== company.industry
				) {
					delete filteredObj[companyId];
				}
			});

			this.items = Object.values(filteredObj);
			this.items = _.sortBy(this.items, ['name']);
			this.totalRows = this.items.length;
		},
		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);

				if (this.isSuperAdmin) {
					filter.withParentCompany = true;
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await companyApi.getCompanies(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allCompaniesObj = _.assign(this.allCompaniesObj, data.companies);
				} else {
					filter.withParentCompany = true;
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await companyApi.getCompanies(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allCompaniesObj = _.assign(this.allCompaniesObj, data.companies);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateSelCompany(item) {
			this.selCompany = item;
			this.$store.commit('SET_CURR_COMPANY', item);
			EventBus.$emit('onUpdateSelCompany', item);
		},
		async openEmailConfirmation(details) {
			try {
				// show loading indicator
				this.isLoading = true;

				let results = await this.loadCompanyDataFromDatabase(details.id);
				let storageLocations = results[0];
				let connections = results[1];
				let users = results[2];

				// hide loading indicator
				this.isLoading = false;

				this.companyDetails = {
					companyDetails: details,
					storageLocations: Object.values(storageLocations),
					connections: Object.values(connections),
					users: Object.values(users),
				};

				this.$bvModal.show('send-email-notification');
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		async loadCompanyDataFromDatabase(companyId) {
			let filter = {
				isActive: 'true',
				company: { id: companyId },
				companyId: companyId,
			};

			let storageLocationResults = storageLocationApi.getStorageLocations(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			let connectionResults = connectionApi.getConnections(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			let userResults = userApi.getUsers(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			return Promise.all([
				storageLocationResults,
				connectionResults,
				userResults,
			]).then((results) => {
				let storageLocations = results[0].data.storageLocations;
				let connections = results[1].data.connections;
				let users = results[2].data.users;
				return Promise.all([storageLocations, connections, users]);
			});
		},

		isParentCompany(company) {
			return this.loggedUserCompany.parentCompanyId === company.id;
		},
		isAdminCompany(company) {
			return company.name === 'TAWITECH, INC.';
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddCompany');
		EventBus.$off('onCloseEditCompany');
		EventBus.$off('onEmailSent');
	},
};
</script>

<style scoped></style>
